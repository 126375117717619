<template>
  <div style="margin: 20px">
    <div class="bg-white p-12">
      <div class="flex items-center mb-4 justify-between">
        <div class="flex items-center">
          <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" style="color: #999999" @click="goBack" />
          <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">
            {{
              isAdd
                ? "Add " + formatEntryType(entryInfo.entry_type)
                : formatEntryType(entryInfo.entry_type)
            }}
          </p>
        </div>
        <div style="float: right" v-if="isAdd || (!isAdminLogin && user_id == entryInfo.user_id)">
          <div class="custom-btn" @click="save">Save & Update</div>
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="Basic Info" name="first">
          <el-form label-position="top">
            <el-form-item label="Title">
              <el-input v-model="entryInfo.title" :disabled="isAdminLogin" style="width: 45%"
                @input="handleTitleChanged" placeholder="Maximum 50 characters"></el-input>
              <p v-if="titleLength > 50" style="color:#ff6450;">{{ 'Entry title characters at maximum 50' }}</p>
            </el-form-item>
            <el-form-item label="Category">
              <el-select v-model="entryInfo.category_id"
                :disabled="isAdminLogin || (entryInfo.category_id != '' && entryInfo.id != '')"
                @change="handleCategoryChanged" style="width: 400px">
                <el-option v-for="(category, index) in types" :key="index" :value="category._id.$id"
                  :label="category.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Team Type" v-if="entryInfo.application_type == '团队'" prop="team_type">
            <el-select v-model="entryInfo.team_type" placeholder="Please select">
              <el-option label="Student Association" value="Student Association"></el-option>
              <el-option label="Student Club" value="Student Club"></el-option>
              <el-option label="Project Team" value="Project Team"></el-option>
            </el-select>
          </el-form-item>

            <el-form-item label="Tags">
              <!-- <el-input :disabled="isAdminLogin" v-model="entryInfo.tags" placeholder="Please input"
                style="width: 45%"></el-input> -->
              <vue-tags-input :disabled="isAdminLogin" style="font-size: 16px;" v-model="tag" :tags="tags" :max-tags="5"
                :placeholder="tags.length > 0 ? '' : tagPlaceHolder" @tags-changed="newTags => tags = newTags" />
            </el-form-item>
            <el-form-item label="Introduction">
              <el-input :disabled="isAdminLogin" v-model="entryInfo.intro" type="textarea" :rows="5"
                placeholder="Please input"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Members" name="second" v-if="entryInfo.application_type == '团队'">
          <PeopleInfo :review_entry_id="entryInfo.id" :entry_user_id="entryInfo.user_id" />
        </el-tab-pane>
        <el-tab-pane label="Activity (-ies)" name="third">
          <div v-if="entryInfo.application_type == '个人'
          ">
            <ActivityIndieStory ref="activityIndieStory" :review_entry_id="entryInfo.id" />
          </div>
          <div v-else-if="entryInfo.application_type == '团队'
          ">
            <ActivityTeamStory ref="activityTeamStory" :entryInfo="entryInfo" :review_entry_id="entryInfo.id" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="Summaries" name="fourth">
          <CategoryInfo :entry_user_id="entryInfo.user_id" ref="categoryInfo1" :source-list="summaryList"
            :category_id="entryInfo.category_id" :show_type="'text'" />
        </el-tab-pane>
        <el-tab-pane label="Materials" name="fifth">
          <CategoryInfo :entry_user_id="entryInfo.user_id" ref="categoryInfo2" :show_type="'file'"
            :source-list="materialList" :category_id="entryInfo.category_id" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {
  getCategoryInfoAndMaterials,
  getEntryPeoplesByReviewEntryId,
  getProjects,
  getReviewEntry,
  getTypes,
  updateCategoryMaterialByUser,
  updateReviewEntry
} from "../../api/eae";
import { ENTRY_TYPES, getUserId, isAdminLogin, createEntryType } from "../../utils/store";
import ActivityIndieStory from "./components/ActivityIndieStory";
import ActivityTeamStory from "./components/ActivityTeamStory";
import PeopleInfo from "./components/PeopleInfo";
import CategoryInfo from "./components/CategoryInfo";
import VueTagsInput from "@johmun/vue-tags-input";
export default {
  name: "EntryEdit",
  components: {
    VueTagsInput,
    CategoryInfo,
    ActivityIndieStory,
    ActivityTeamStory,
    PeopleInfo,
  },
  data() {
    return {
      tagPlaceHolder: 'Maximum 5 tags, click "enter" for each',
      separators: [","],
      tag: '',
      tags: [],
      titleLength: 0,
      user_id: getUserId(),
      isAdminLogin: isAdminLogin(),
      materialList: [],
      summaryList: [],
      types: [],
      projects: [],
      activeName: "first",
      entryInfo: {
        id: "",
        category_id: "",
        title: "",
        intro: "",
        open_status: "YES",
        entry_type: "",
        project_id: "",
        user_id: "",
        application_type: '',
        team_type: '',
        tags: '',
        feature_award_id: ''
      },
    };
  },
  computed: {
    isAdd() {
      return !this.$route.query.id;
    },
  },
  //Review Entry里面，年份最好直接改成项目的id (project_id)，因为一年一度么。然后后台逻辑限制每个年份只能最多有3个Entry
  mounted() {
    this.entryInfo.entry_type = this.$route.query.type;
    this.initData();
  },
  methods: {

    handleTitleChanged(e) {
      this.titleLength = this.entryInfo.title.length;
    },

    handleCategoryChanged() {
      this.materialList = [];
      this.summaryList = [];
      getCategoryInfoAndMaterials(getUserId(), this.entryInfo.category_id).then(
        (res) => {
          let result = res.data.data;
          let sources = result.category.sourceList;
          let temp_sourceList = [];
          if (sources && sources.length > 0) {
            for (let i = 0; i < sources.length; i++) {
              let source = sources[i];
              let entry_type = this.entryInfo.entry_type
              if (entry_type == 'story_line' || entry_type == 'single_activity') {
                entry_type = createEntryType(this.entryInfo.application_type, entry_type)
              }
              if (
                source.suit &&
                source.suit.indexOf(entry_type) >= 0
              ) {
                temp_sourceList.push(source);
              }
            }
          }
          if (result.material) {
            for (let i = 0; i < temp_sourceList.length; i++) {
              temp_sourceList[i].value = result.material.materials[i];
            }
          }
          for (let i = 0; i < temp_sourceList.length; i++) {
            if (temp_sourceList[i].type == "file") {
              this.materialList.push(temp_sourceList[i]);
            } else {
              this.summaryList.push(temp_sourceList[i]);
            }
          }
        }
      );
    },

    async initData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let temp_projects = await getProjects();
      this.projects = temp_projects.data.data;

      let temp_types = await getTypes();
      this.types = [
        ...temp_types.data.data.filter((item) => item.name !== "Others"),
        ...temp_types.data.data.filter((item) => item.name === "Others"),
      ];
      this.fetchData();
      loading.close();
    },

    formatEntryType(entry_type) {
      if (entry_type == "team_story" || entry_type == "indie_story") {
        return 'Storyline'

      } else if (entry_type == "team_exp" || entry_type == "indie_exp") {
        return 'Single Activity'
      }

      return ENTRY_TYPES[entry_type];
    },

    fetchData() {
      if (!this.isAdd) {
        const id = this.$route.query.id;
        getReviewEntry(id).then((res) => {
          this.entryInfo = res.data.data;
          this.entryInfo.id = id;
          if (this.entryInfo.tags && this.entryInfo.tags != '') {
            this.tags = []
            let temp_tags = this.entryInfo.tags.split(',')
            for (var i = 0; i < temp_tags.length; i++) {
              var tag = { text: temp_tags[i] }
              this.tags.push(tag)
            }
          }
          this.handleCategoryChanged();
        });
      }
    },
    checkCategoryIsClosed() {
      if (this.isAdd) {
        for (let i = 0; i < this.types.length; i++) {
          let category = this.types[i];
          if (category._id.$id == this.entryInfo.category_id) {
            let submission_ddl = category.submission_ddl;
            if (submission_ddl) {
              let currentTime = new Date().getTime();
              if (submission_ddl * 1000 < currentTime) {
                this.$message.error('The chosen category has already closed for submission.')
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    async save() {
      if (this.entryInfo.title == "") {
        this.$message.error("Please input title");
        return;
      }
      let titleLength = this.entryInfo.title.length;
      if (titleLength > 50) {
        this.$message.error("Entry title characters at maximum 50");
        return;
      }

      if (this.checkCategoryIsClosed()) {
        return
      }
      this.entryInfo.tags = ''
      if (this.tags.length > 0) {
        let length = this.tags.length
        for (var i = 0; i < length; i++) {
          var tag = this.tags[i].text
          this.entryInfo.tags = this.entryInfo.tags + tag
          if (i != (length - 1)) {
            this.entryInfo.tags = this.entryInfo.tags + ","
          }
        }
      }
      if (this.entryInfo.id && this.entryInfo.id != "" && this.entryInfo.application_type == '团队') {
        let result = await getEntryPeoplesByReviewEntryId(this.entryInfo.id);
        let members = result.data.data;
        if (members.length < 3) {
          this.$message.warning(
            "You can't submit the team entry when your team only has one member. Please invite other members to your team."
          );
          return;
        }
      }
      let params = {
        id: this.entryInfo.id,
        category_id: this.entryInfo.category_id,
        title: this.entryInfo.title,
        intro: this.entryInfo.intro,
        user_id: getUserId(),
        open_status: this.entryInfo.open_status,
        entry_type: this.entryInfo.entry_type,
        application_type: this.entryInfo.application_type,
        team_type: this.entryInfo.team_type,
        tags: this.entryInfo.tags,
        feature_award_id: this.entryInfo.feature_award_id
      };
      let new_data = this.summaryList.concat(this.materialList);
      let materials = [];
      for (let i = 0; i < new_data.length; i++) {
        materials.push(new_data[i].value);
      }
      updateCategoryMaterialByUser(
        getUserId(),
        this.entryInfo.category_id,
        materials
      ).then((res) => { });
      updateReviewEntry(params).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          this.$message.success("Saved Successfully");
          if (this.entryInfo.id && this.entryInfo.id != "") {
            this.fetchData();
            return;
          }
          this.$router.push({
            path: "/home/reviewEntries",
          });
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    goBack() {
      if (this.isAdminLogin) {
        this.$router.go(-1);
        return;
      }
      this.$router.push({
        path: "/home/reviewEntries",
      });
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  display: none;
}

.ti-tag {
  background-color: #ff6450 !important;
  /* 您想要的颜色 */
}

.ti-input {
  border-radius: 5px;
}
</style>

<style scoped lang="scss">
.custom-btn {
  border-radius: 5px;
  padding: 6px 10px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  background: #ff6450;
  width: fit-content;
}
</style>
