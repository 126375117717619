<template>
  <div>
    <p class="font-bold mt-3 text-lg mb-3">Members</p>

    <div style="text-align: right">
      <el-button v-if="!isAdminLogin && entry_user_id != '' && entry_user_id == loginUserId" type="primary" size="small"
        @click="clickShowEntryPeopleDialog(null)">Add Member
      </el-button>
      <div class="flex flex-wrap">
        <div v-for="(item, index) in entryPeoples" :key="index" class="person-card my-4">
          <person :entry_user_id="entry_user_id" :info="item" :index="index" @edit="clickShowEntryPeopleDialog(item)"
            @remove="clickRemoveEntryPeople(item)" />
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showEntryPeopleDialog" :title="entryPeopleInfo.id == '' ? 'Add Entry Member' : 'Edit Entry Member'
        ">
      <div>
        <el-form :model="entryPeopleInfo" :rules="rules" ref="entryPeopleForm" label-position="left">
          <el-form-item label="Name" v-if="entryPeopleInfo.id !== ''">
            <el-input v-model="entryPeopleInfo.name" placeholder="Please input name" disabled></el-input>
          </el-form-item>
          <el-form-item label="Role" prop="role">
            <el-input v-model="entryPeopleInfo.role"
              placeholder="Please input, team leader, assistant, secretary, member, etc."></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="entryPeopleInfo.email" placeholder="Please input"
              :disabled="entryPeopleInfo.id !== ''"></el-input>
          </el-form-item>
          <el-form-item label="Team Credits" prop="team_credits">
            <el-input type="textarea" :rows="3" v-model="entryPeopleInfo.team_credits"
              placeholder="Please input team credits"></el-input>
          </el-form-item>
        </el-form>

        <el-button style="margin-top: 20px" type="primary" size="small" @click="confirmEntryPeople()">Submit
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addCurrentLoginStudentToEntryPeople,
  getEntryPeoplesByReviewEntryId,
  getReviewEntry,
  removeEntryPeople,
  updateEntryPeople,
} from "../../../api/eae";
import Person from "../../../components/Person.vue";
import { getUserId, isAdminLogin } from "../../../utils/store";

export default {
  name: "PeopleInfo",
  components: { Person },
  props: {
    entry_user_id: {
      type: String,
      default: "",
    },
    review_entry_id: {
      type: String,
      default: "",
    },
  },
  watch: {
    review_entry_id: {
      handler(newVal, oldVal) {
        this.doAddCurrentLoginStudentToEntryPeople();
      },
      immediate: true,
    },
  },
  data() {
    return {
      loginUserId: getUserId(),
      isAdminLogin: isAdminLogin(),
      entryInfo: {},
      entryPeoples: [],
      entryPeopleInfo: {
        user_id: getUserId(),
        id: "",
        review_entry_id: "",
        role: "",
        email: "",
        team_credits: "",
      },
      rules: {
        role: [{ required: true, message: "Please input", trigger: "blur" }],
        email: [
          { required: true, message: "Please input ", trigger: "blur" },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        team_credits: [
          { required: true, message: "Please input ", trigger: "blur" },
        ],
      },
      showEntryPeopleDialog: false,
    };
  },
  mounted() { },
  methods: {
    doAddCurrentLoginStudentToEntryPeople() {
      if (this.review_entry_id == "") {
        return;
      }
      let user_id = getUserId();
      //这个接口必须要优先调用
      addCurrentLoginStudentToEntryPeople(user_id, this.review_entry_id).then(
        (res) => {
          this.doRequestReviewEntry();
        }
      );
    },
    doRequestReviewEntry() {
      getReviewEntry(this.review_entry_id).then((res) => {
        this.entryInfo = res.data.data;
        this.entryInfo.id = this.review_entry_id;
        this.requestEntryPeoples();
      });
    },
    requestEntryPeoples() {
      getEntryPeoplesByReviewEntryId(this.entryInfo.id).then((res) => {
        this.entryPeoples = res.data.data;
      });
    },
    clickRemoveEntryPeople(row) {
      if (this.isAdminLogin) {
        return;
      }
      this.$alert(
        "Are you sure you want to remove this member?",
        "Remove Member",
        {
          confirmButtonText: "Confirm",
          callback: (action) => {
            if (action == "confirm") {
              removeEntryPeople(this.entryInfo.id, row._id.$id).then((res) => {
                this.$message.success("Removed Successfully");
                this.requestEntryPeoples();
              });
            }
          },
        }
      );
    },
    clickShowEntryPeopleDialog(entry) {
      if (this.isAdminLogin) {
        return;
      }
      if (entry) {
        this.entryPeopleInfo.id = entry._id.$id;
        this.entryPeopleInfo.email = entry.student.student_email;
        this.entryPeopleInfo.role = entry.role;
        this.entryPeopleInfo.review_entry_id = this.entryInfo.id;
        this.entryPeopleInfo.team_credits = entry.team_credits;
        this.entryPeopleInfo.name = `${entry.student.student_givenName} ${entry.student.student_lastName}`;
      } else {
        this.entryPeopleInfo.id = "";
        this.entryPeopleInfo.email = "";
        this.entryPeopleInfo.role = "";
        this.entryPeopleInfo.team_credits = "";
        this.entryPeopleInfo.review_entry_id = this.entryInfo.id;
      }
      this.showEntryPeopleDialog = true;
    },
    confirmEntryPeople() {
      if (this.review_entry_id == "") {
        this.$message.error("Please Add entry first~");
        return;
      }

      this.$refs["entryPeopleForm"].validate((valid) => {
        if (valid) {
          let params = {
            user_id: this.entryPeopleInfo.user_id,
            id: this.entryPeopleInfo.id,
            review_entry_id: this.entryPeopleInfo.review_entry_id,
            role: this.entryPeopleInfo.role,
            email: this.entryPeopleInfo.email,
            team_credits: this.entryPeopleInfo.team_credits,
          };
          updateEntryPeople(params).then((res) => {
            let result = res.data;
            if (result.code == 0) {
              this.$message.success("Submit Successful");
              this.showEntryPeopleDialog = false;
              this.requestEntryPeoples();
            } else {
              this.$message.error(result.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.person-card {
  width: calc(50% - 20px);
  margin: 16px 10px;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.08);
}

.activity-container {
  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }

  .activity-info {
    .activity-name {
      color: #333333;
      text-align: left;
    }

    .activity-time {
      color: #ff8900;

      // span {
      //   color: #999999;
      // }
      p {
        margin-right: 45px;
      }
    }

    .activity-location {
      color: #ff8900;

      span {
        color: #999999;
      }

      p {
        margin-right: 60px;
      }
    }

    .activity-desc {
      color: #999999;
    }

    .btn {
      width: 90px;
      height: 30px;
      cursor: pointer;
      border-radius: 5px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 15px;
    }

    .edit-btn {
      background: #579eff;
    }

    .delete-btn {
      border: 2px solid #ff8474;
      margin-left: 16px;
      color: #ff8474;
    }

    .info {
      display: inline-block;
      margin-left: 12px;
      color: #ff8474;
    }

    .success {
      color: #2fd098;
    }
  }
}
</style>
