var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-container p-6 bg-white flex items-center justify-between"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"activity-info"},[_c('p',{staticClass:"activity-name text-base font-bold mb-4"},[_vm._v(" "+_vm._s(_vm.info.student.student_givenName_pinyin || '-')+" "+_vm._s(_vm.info.student.student_lastName_pinyin || '-')+" ")]),_c('div',{staticClass:"activity-time flex text-sm font-bold mb-6"},[_c('p',{staticStyle:{"color":"#579eff"}},[_vm._v(_vm._s(_vm.info.student.student_email))]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.info.role))])])]),_c('div',{staticClass:"flex text-sm font-bold mb-6"},[_c('p',[_vm._v(" Register: "),_c('span',{class:[
          { success: _vm.info.app_status !== _vm.STATUS_ENUM.NO_REGISTER },
          'info',
        ]},[_vm._v(_vm._s(_vm.info.app_status === _vm.STATUS_ENUM.NO_REGISTER ? "NO" : "YES"))])]),(true)?_c('p',{staticClass:"ml-8"},[_vm._v(" Payment: "),_c('span',{class:[
          { success: _vm.info.app_status == 3 },
          'info',
        ]},[_vm._v(_vm._s(_vm.info.app_status == 3 ? "YES" : "NO"))])]):_vm._e()]),_c('div',{staticClass:"mb-4 flex"},[(_vm.user_id == _vm.entry_user_id)?_c('div',{staticClass:"btn edit-btn",on:{"click":_vm.handleEdit}},[_vm._v("Edit")]):_vm._e(),(_vm.user_id == _vm.entry_user_id)?_c('div',[(_vm.user_id != _vm.info.student_id)?_c('div',{staticClass:"btn delete-btn",on:{"click":_vm.handleRemove}},[_vm._v("Delete")]):_vm._e()]):_c('div',[(_vm.user_id == _vm.info.student_id)?_c('div',{staticClass:"btn delete-btn",on:{"click":_vm.handleRemove}},[_vm._v("Delete")]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }