<template>
  <div class="activity-container p-6 bg-white flex items-center justify-between">
    <div class="flex">
      <!-- <div class="sort font-bold mr-6">{{ index + 1 }}</div> -->
      <div class="activity-info">
        <p class="activity-name text-base font-bold mb-4">
          {{ info.student.student_givenName_pinyin || '-' }}
          {{ info.student.student_lastName_pinyin || '-' }}
        </p>
        <div class="activity-time flex text-sm font-bold mb-6">
          <p style="color: #579eff">{{ info.student.student_email }}</p>
          <p>
            <span>{{ info.role }}</span>
          </p>
        </div>
        <div class="flex text-sm font-bold mb-6">
          <p>
            Register:
            <span :class="[
            { success: info.app_status !== STATUS_ENUM.NO_REGISTER },
            'info',
          ]">{{
            info.app_status === STATUS_ENUM.NO_REGISTER ? "NO" : "YES"
          }}</span>
          </p>
          <p v-if="true" class="ml-8">
            Payment:
            <span :class="[
            { success: info.app_status == 3 },
            'info',
          ]">{{
            info.app_status == 3 ? "YES" : "NO"
          }}</span>
          </p>
        </div>
        <div class="mb-4 flex">
          <div class="btn edit-btn" v-if="user_id == entry_user_id" @click="handleEdit">Edit</div>
          <div v-if="user_id == entry_user_id">
            <div class="btn delete-btn" v-if="user_id != info.student_id" @click="handleRemove">Delete</div>
          </div>
          <div v-else>
            <div class="btn delete-btn" v-if="user_id == info.student_id" @click="handleRemove">Delete</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS_ENUM } from "../constants/index.js";
import {
  getUserId
} from "../utils/store";

export default {
  name: "Person",
  props: {
    entry_user_id: {
      type: String,
      default: "",
    },
    info: Object,
    index: Number,
  },
  data() {
    return {
      user_id: getUserId(),
      STATUS_ENUM,
    };
  },
  mounted() {
  },
  methods: {
    handleEdit() {
      this.$emit("edit");
    },
    handleRemove() {
      this.$emit("remove");
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-container {
  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }

  .activity-info {
    .activity-name {
      color: #333333;
      text-align: left;
    }

    .activity-time {
      color: #ff8900;

      // span {
      //   color: #999999;
      // }
      p {
        margin-right: 45px;
      }
    }

    .activity-location {
      color: #ff8900;

      span {
        color: #999999;
      }

      p {
        margin-right: 60px;
      }
    }

    .activity-desc {
      color: #999999;
    }

    .btn {
      width: 90px;
      height: 30px;
      cursor: pointer;
      border-radius: 5px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 15px;
    }

    .edit-btn {
      background: #579eff;
    }

    .delete-btn {
      border: 2px solid #ff8474;
      margin-left: 16px;
      color: #ff8474;
    }

    .info {
      display: inline-block;
      margin-left: 12px;
      color: #ff8474;
    }

    .success {
      color: #2fd098;
    }
  }
}
</style>
