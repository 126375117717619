<template>
  <div>
    <el-form label-position="top">
    </el-form>

    <div style="text-align: right">
      <el-button type="primary" size="small" v-if="!isAdminLogin && entryInfo.user_id == user_id" @click="clickAddActivity(null)"
      >Add Team Activity
      </el-button>
    </div>

    <el-timeline>
      <el-timeline-item
          v-for="(activity, index) in entry_activities"
          :key="index"
          :timestamp="index"
          :hide-timestamp="true"
      >
        <div class="shadow">
          <Activity
              :info="activity"
              :index="index"
              :hideOps="true"
              :showRemove="entryInfo.user_id !='' && entryInfo.user_id == user_id"
              :showEdit="entryInfo.user_id !='' && entryInfo.user_id == user_id"
              @remove="removeActivity(activity)"
              @edit="clickAddActivity(activity)"
          />
        </div>
      </el-timeline-item>
    </el-timeline>

    <el-dialog
        :visible.sync="showAddActivityDialog"
        title="Add TeamActivity"
        width="70%"
    >
      <div>
        <el-form
            label-position="top"
            :model="activityInfo"
            :rules="rules"
            ref="activityInfo"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="Type Name" prop="type">
                <el-input
                    v-model="activityInfo.type"
                    placeholder="Please input type name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="Start Time" prop="start_time">
                <el-date-picker
                    v-model="activityInfo.start_time"
                    type="month"
                    value-format="timestamp"
                    placeholder="Please select start time"
                    style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="End Time" prop="end_time">
                <el-date-picker
                    v-model="activityInfo.end_time"
                    type="month"
                    value-format="timestamp"
                    placeholder="Please select end time"
                    style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="Hours/Week" prop="hours_peer_week">
                <el-input
                    v-model="activityInfo.hours_peer_week"
                    placeholder="Please input Hours/Week"
                    type="number"
                    min="0"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="Leadership Position" prop="position">
                <el-input
                    v-model="activityInfo.position"
                    placeholder="Please input Leadership Position"
                    maxlength="50"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="Organization" prop="org">
                <el-input
                    v-model="activityInfo.org"
                    placeholder="Please input Organization"
                    maxlength="100"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Description" prop="desc">
            <el-input
                v-model="activityInfo.desc"
                placeholder="Please input Description"
                type="textarea"
                maxlength="150"
                :rows="5"
            ></el-input>
          </el-form-item>

          <el-form-item label="Attachment" prop="">
            <div style="display: flex">
              <span v-if="attachmentUrl && attachmentUrl!=''" style="margin-right: 20px">{{ attachmentUrl }}</span>
              <el-button size="small" type="primary" @click="clickShowAttachmentDialog">
                {{ attachmentUrl && attachmentUrl != '' ? 'Edit' : ' Add' }} Attachment
              </el-button>
            </div>
          </el-form-item>

        </el-form>
        <el-button
            type="primary"
            @click="submitTeamActivity"
            style="margin-top: 20px"
        >{{ activityInfo.id ? "Save" : "Add" }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showAttachmentDialog" title="Activity Attachment">
      <el-form>
        <el-form-item label="Upload Method:">
          <el-select v-model="uploadType">
            <el-option label="Local Upload" value="local"></el-option>
            <el-option label="Remote Upload" value="url"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Remote Url:" v-if="uploadType === 'url'">
          <div class="flex">
            <el-input v-model="attachmentUrl" class="w-1/2"/>
          </div>
        </el-form-item>
        <el-upload
            class="upload-demo mt-2"
            ref="upload"
            action="#"
            :limit="1"
            :before-upload="beforeAttachUpload"
            v-if="uploadType === 'local'"
        >
          <el-button slot="trigger" size="small" type="primary"
          >Select File
          </el-button
          >
        </el-upload>
      </el-form>
      <el-button type="primary" @click="showAttachmentDialog=false" style="margin-top: 20px">Confirm</el-button>
    </el-dialog>

  </div>
</template>

<script>
import {
  getReviewEntryActivities,
  updateTeamActivity,
  removeTeamActivityFromReviewEntry,
  getSingaporeQiniuToken
} from "../../../api/eae";
import {getUserId,isAdminLogin} from "../../../utils/store";
import Activity from "../../../components/Activity.vue";
import * as qiniu from 'qiniu-js'

export default {
  name: "ActivityTeamStory",
  props: {
    review_entry_id: {
      type: String,
      default: "",
    },
    entryInfo: {
      type: Object,
      default: {},
    },
  },
  components: {
    Activity,
  },
  watch: {
    review_entry_id: {
      handler(newVal, oldVal) {
        this.requestEntryActivities();
      },
      immediate: true,
    },
  },
  data() {
    return {
      user_id:getUserId(),
      isAdminLogin: isAdminLogin(),
      showAttachmentDialog: false,
      entry_activities: [],
      showAddActivityDialog: false,
      activityInfo: {
        id: "",
        type: "",
        position: "",
        org: "",
        desc: "",
        hours_peer_week: "",
        start_time: "",
        end_time: "",
      },
      rules: {
        desc: [
          {
            required: true,
            message: "Please input Description",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Please input type name",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            message: "Please input Leadership Position",
            trigger: "blur",
          },
        ],
        org: [
          {
            required: true,
            message: "Please input Organization",
            trigger: "blur",
          },
        ],
        hours_peer_week: [
          {
            required: true,
            message: "Please input Hours/Week",
            trigger: "blur",
          },
        ],
        start_time: [
          {
            required: true,
            message: "Please select start time",
            trigger: "blur",
          },
        ],
        end_time: [
          {
            required: true,
            message: "Please select end time",
            trigger: "blur",
          },
        ],
      },
      attachmentUrl: "",
      files: [],
      uploadType: "url",
    };
  },
  mounted() {
  },
  methods: {

    clickShowAttachmentDialog() {
      this.attachmentUrl = ''
      this.showAttachmentDialog = true
    },

    async beforeAttachUpload(file) {
      const result = await this.getQiniuToken()
      if (result.data.code === 0) {
        const token = result.data.data
        this.observable = qiniu.upload(file, new Date().getTime() + file.name, token)
        const observer = {
          complete: (res) => {
            this.attachmentUrl = 'https://ap.learningfirst.tech/' + res.key
            this.showAttachmentDialog = false
          }
        }
        this.observable.subscribe(observer)
      } else {
      }
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getSingaporeQiniuToken().then((res) => {
          resolve(res)
        })
      })
    },
    removeActivity(row) {
      if(this.isAdminLogin) {
        return
      }
      this.$alert(
          "Are you sure to remove this activity from the current entry?",
          "Remove Activity",
          {
            confirmButtonText: "Confirm",
            callback: (action) => {
              if (action == "confirm") {
                removeTeamActivityFromReviewEntry(
                    this.review_entry_id,
                    row._id.$id
                ).then((res) => {
                  let result = res.data;
                  if (result.code == 0) {
                    this.$message.success("Removed Successfully");
                    this.requestEntryActivities();
                  } else {
                    this.$message.success(result.msg);
                  }
                });
              }
            },
          }
      );
    },
    clickAddActivity(row) {
      if(this.isAdminLogin) {
        return
      }
      this.attachmentUrl = ''
      if (row) {
        this.activityInfo.id = row._id.$id;
        this.activityInfo.type = row.type;
        this.activityInfo.position = row.position;
        this.activityInfo.org = row.org;
        this.activityInfo.desc = row.desc;
        this.activityInfo.hours_peer_week = row.hours_peer_week;
        this.activityInfo.start_time = row.start_time * 1000;
        this.activityInfo.end_time = row.end_time * 1000;
        if (row.attachmentUrl) {
          this.attachmentUrl = row.attachmentUrl
        }
      } else {
        this.activityInfo.id = "";
        this.activityInfo.type = "";
        this.activityInfo.position = "";
        this.activityInfo.org = "";
        this.activityInfo.desc = "";
        this.activityInfo.hours_peer_week = "";
        this.activityInfo.start_time = "";
        this.activityInfo.end_time = "";
      }

      this.showAddActivityDialog = true;
    },
    requestEntryActivities() {
      if (this.review_entry_id == "") {
        return;
      }
      getReviewEntryActivities(this.review_entry_id).then((res) => {
        this.entry_activities = res.data.data;
      });
    },
    submitTeamActivity() {
      if (this.review_entry_id == "") {
        this.$message.error("Please Add entry first~");
        return;
      }
      this.$refs["activityInfo"].validate((valid) => {
        if (valid) {
          if (this.activityInfo.end_time <= this.activityInfo.start_time) {
            this.$message.error("End time must greater than start time ~");
            return;
          }
          let params = {
            review_entry_id: this.review_entry_id,
            user_id: getUserId(),
            id: this.activityInfo.id,
            type: this.activityInfo.type,
            position: this.activityInfo.position,
            org: this.activityInfo.org,
            desc: this.activityInfo.desc,
            hours_peer_week: this.activityInfo.hours_peer_week,
            start_time: this.activityInfo.start_time / 1000,
            end_time: this.activityInfo.end_time / 1000,
            attachmentUrl: this.attachmentUrl,
          };
          updateTeamActivity(params).then((res) => {
            this.$message.success("Submit Successful");
            this.showAddActivityDialog = false;
            this.requestEntryActivities();
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
