<template>
  <div style="margin-top: 25px">
    <div v-if="show_type == 'file' && categoryMaterialInfo.type == 'file'">
      <p v-html="categoryMaterialInfo.name"></p>
      <p style="margin-top: 15px; font-size: 14px">
        {{ categoryMaterialInfo.intro }}
      </p>
      <div style="display: flex; width: 100%; margin-top: 15px">
        <el-button
          style="width: 60px; height: 32px; margin-right: 20px"
          size="small"
          type="primary"
          v-if="fileUrl && fileUrl != ''"
          @click="openFile"
        >
          View
        </el-button>
        <el-upload
          :disabled="isAdminLogin"
          class="upload-demo"
          action="#"
          :limit="1"
          :before-upload="beforeAttachUpload"
        >
          <div
            slot="tip"
            class="el-upload__tip"
            v-if="fileUrl && fileUrl != ''"
          ></div>
          <el-button slot="trigger" size="small" type="primary">
            {{ fileUrl && fileUrl != "" ? "Replace" : "Select File" }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            style="margin-left: 20px"
            v-if="fileUrl && fileUrl != ''"
            @click="removeFile"
          >
            Remove
          </el-button>
        </el-upload>
        <p style="margin-left: 20px; margin-top: 6px">{{ fileName }}</p>
      </div>
    </div>

    <div v-else-if="show_type == 'text' && categoryMaterialInfo.type != 'file'">
      <p v-html="categoryMaterialInfo.name"></p>

      <p style="margin-top: 15px; font-size: 14px">
        {{ categoryMaterialInfo.intro }}
      </p>

      <el-input
        :disabled="isAdminLogin"
        style="margin-top: 15px"
        v-model="categoryMaterialInfo.value"
        type="textarea"
        :rows="5"
      ></el-input>
    </div>
  </div>
</template>

<script>
import * as qiniu from "qiniu-js";
import { getSingaporeQiniuToken } from "../api/eae";
import { isAdminLogin, getUserId } from "../utils/store";
import { Loading } from "element-ui";
export default {
  name: "CategoryMaterial",
  props: {
    entry_user_id: {
      type: String,
      default: "",
    },
    categoryMaterialInfo: {
      type: Object,
      default: {},
    },
    show_type: {
      type: String,
      default: "",
    },
  },
  watch: {
    categoryMaterialInfo: {
      handler(newVal, oldVal) {
        if (newVal.type == "file") {
          let value = newVal.value;
          let fileName = "";
          let temp = value.split("&&");
          let url = temp[0];
          if (temp.length > 1) {
            fileName = temp[1];
          }
          this.fileUrl = url;
          this.fileName = fileName;
          if(this.fileUrl!='' && this.fileName == ''){
              this.fileName = 'file'
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      user_id: getUserId(),
      isAdminLogin: isAdminLogin(),
      showAttachmentDialog: false,
      fileName: "",
      fileUrl: "",
      loadingInstance: undefined,
    };
  },
  methods: {
    openFile() {
      window.open(this.fileUrl, "_blank");
    },
    clickShowAttachmentDialog() {
      this.showAttachmentDialog = true;
    },
    async beforeAttachUpload(file) {
      if (
        this.isAdminLogin ||
        (this.entry_user_id != "" && this.entry_user_id != this.user_id)
      ) {
        this.$message.error("No permission~");
        return;
      }
      let that = this;
      this.showLoadingView();
      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            let url = "https://ap.learningfirst.tech/" + res.key;
            // url + "&&" + file.name; 搞出问题来了
            this.categoryMaterialInfo.value = url;
            this.fileName = file.name;
            this.fileUrl = url;
            this.$message.success("Upload Successful");
            this.closeLoadingView();
          },
        };
        this.observable.subscribe(observer);
      } else {
        this.closeLoadingView();
      }
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getSingaporeQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },
    showLoadingView() {
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: "Uploading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    closeLoadingView() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    removeFile() {
      this.categoryMaterialInfo.value = "";
      this.fileName = "";
      this.fileUrl = "";
    },
  },
};
</script>

<style scoped></style>
