<template>
  <div>
    <el-form label-position="left">
      <h3
        v-if="category_id && show_type == 'file'"
        style="margin-top: 10px; margin-bottom: 10px"
      >
        Required materials to submit
      </h3>
      <p v-if="!category_id">Please select category first</p>
      <el-empty
        description="No Data Yet"
        v-if="sourceList && sourceList.length === 0"
      ></el-empty>
      <div
        v-for="(item, index) in sourceList"
        :key="index"
        style="margin-top: 10px"
      >
        <CategoryMaterial
          :entry_user_id="entry_user_id"
          :category-material-info="item"
          :show_type="show_type"
        ></CategoryMaterial>
      </div>
    </el-form>
  </div>
</template>

<script>
import CategoryMaterial from "../../../components/CategoryMaterial";

export default {
  name: "CategoryInfo",
  components: {
    CategoryMaterial,
  },
  props: {
    entry_user_id: {
      type: String,
      default: "",
    },
    sourceList: [],
    entry_type: {
      type: String,
      default: "",
    },
    show_type: {
      type: String,
      default: "",
    },
    category_id: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
.ql-container {
  height: 180px !important;
}
</style>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px !important;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
  object-fit: cover;
}
</style>
