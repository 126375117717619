<template>
  <div>
    <div class="flex gap-3 flex-row-reverse">
      <div v-if="!isAdminLogin && entry_activities.length < 1" class="custom-btn" style="background-color: #579eff"
        @click="clickAddActivity">
        Add Activity
      </div>
      <!-- <div v-if="!isAdminLogin" class="custom-btn" style="background-color: #2fd098" @click="clickSortActivity">
        Sort Activity
      </div> -->
    </div>
    <el-timeline>
      <el-timeline-item v-for="(activity, index) in entry_activities" :key="index" :timestamp="index"
        :hide-timestamp="true">
        <div class="shadow">
          <Activity :info="activity" :index="index" :hideOps="true" :showRemove="true"
            @remove="removeActivity(activity)" />
        </div>
      </el-timeline-item>
    </el-timeline>

    <el-dialog :visible.sync="showAddActivityDialog" title="Add Activity" width="70%">
      <div>
        <el-table :data="activities" :border="true">
          <el-table-column type="index" prop="index" label="Index" width="120px" />
          <el-table-column prop="type" label="Activity Type"></el-table-column>
          <el-table-column prop="desc" label="Activity Desc"></el-table-column>
          <el-table-column prop="" label="Activity StartTime">
            <template slot-scope="scope">
              <div>
                {{ (scope.row.start_time && scope.row.start_time != '') ? $dayjs(scope.row.start_time *
                  1000).format("YYYY/MM/DD") : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="Activity EndTime">
            <template slot-scope="scope">
              {{ (scope.row.end_time && scope.row.end_time != '') ? $dayjs(scope.row.end_time *
                1000).format("YYYY/MM/DD") : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="Operate">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="confirmAddActivity(scope.row)">Select
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="Sort Activity" width="80%" :visible.sync="showSortActivityDialog" @opened="initSort">
      <div id="sort-container" style="background: #f8f8f8">
        <div v-for="item in sortData" :key="item._id.$id"
          class="activity-container p-6 bg-white my-4 flex items-center justify-between shadow-lg">
          <div class="flex">
            <div class="activity-info">
              <p class="activity-name text-base font-bold mb-4">
                {{ item.type }}
              </p>
              <div class="activity-time flex text-sm font-bold mb-2">
                <p>
                  <span>Start Time：</span>{{ (item.start_time && item.start_time != '') ? $dayjs(item.start_time *
                    1000).format("YYYY/MM/DD") : '' }}
                </p>
                <p>
                  <span>End Time：</span>{{ (item.end_time && item.end_time != '') ? $dayjs(item.end_time *
                    1000).format("YYYY/MM/DD") : '' }}
                </p>
                <!-- <p style="color: #579eff">
                  {{ item.hours_peer_week }} hours peer week
                </p> -->
              </div>
              <div class="activity-location flex text-sm font-bold mb-4">
                <p><span>Leadership Position：</span>{{ item.position }}</p>
                <p><span>Organization：</span>{{ item.org }}</p>
              </div>
              <!-- <div class="activity-desc">
                <p class="text-sm font-bold">
                  {{ item.desc }}
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: right">
        <el-button @click="updateDataSequence" type="primary">Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addActivityToReviewEntry,
  getMyActivities,
  getReviewEntryActivities,
  removeActivityFromReviewEntry,
  updateReviewEntryActivityDataSequence,
} from "../../../api/eae";
import { getUserId, isAdminLogin } from "../../../utils/store";
import Activity from "../../../components/Activity.vue";
import Sortable from "sortablejs";

export default {
  name: "ActivityIndieStory",
  components: {
    Activity,
  },
  props: {
    review_entry_id: {
      type: String,
      default: "",
    }
  },
  watch: {
    review_entry_id: {
      handler(newVal, oldVal) {
        this.requestEntryActivities();
      },
      immediate: true,
    },
  },
  data() {
    return {
      isAdminLogin: isAdminLogin(),
      entry_activities: [],
      activities: [],
      showAddActivityDialog: false,
      showSortActivityDialog: false,
      sortData: [],
      files: [],
      uploadType: "url",
    };
  },
  mounted() {
    this.fetchActivities();
  },
  methods: {
    initSort() {
      Sortable.create(document.getElementById("sort-container"), {
        onEnd: (e) => {
          const currRow = this.sortData.splice(e.oldIndex, 1)[0];
          this.sortData.splice(e.newIndex, 0, currRow);
        },
      });
    },
    updateDataSequence() {
      if (this.review_entry_id == "") {
        this.$message.error("Please Add entry first~");
        return;
      }
      let ids = this.sortData.map((item) => item._id.$id);
      updateReviewEntryActivityDataSequence(this.review_entry_id, ids).then(
        (res) => {
          this.showSortActivityDialog = false;
          this.$message.success("Sort success ~");
          this.requestEntryActivities();
        }
      );
    },
    clickSortActivity() {
      this.showSortActivityDialog = true;
      this.sortData = JSON.parse(JSON.stringify(this.entry_activities));
    },

    requestEntryActivities() {
      if (this.review_entry_id == "") {
        return;
      }
      getReviewEntryActivities(this.review_entry_id).then((res) => {
        this.entry_activities = res.data.data;
      });
    },

    fetchActivities() {
      getMyActivities(getUserId()).then((res) => {
        this.activities = res.data.data;
      });
    },
    clickAddActivity() {
      if (this.activities.length <= 0) {
        this.$alert(
          'To submit an individual entry for review, you should first add the corresponding activity in the "My Activities" section. Once the activity is added, you can select it when adding the review entry. The "My Activities" section serves as a storage space for you to keep track of the activities you have participated in. ',
          "Tips",
          {
            confirmButtonText: "OK",
          }
        );
        return;
      }
      this.showAddActivityDialog = true;
    },
    removeActivity(row) {
      this.$alert(
        "Are you sure to remove this activity from the current entry?",
        "Remove Activity",
        {
          confirmButtonText: "Confirm",
          callback: (action) => {
            if (action == "confirm") {
              removeActivityFromReviewEntry(
                this.review_entry_id,
                row._id.$id
              ).then((res) => {
                let result = res.data;
                if (result.code == 0) {
                  this.$message.success("Removed Successfully");
                  this.requestEntryActivities();
                } else {
                  this.$message.success(result.msg);
                }
              });
            }
          },
        }
      );
    },
    confirmAddActivity(row) {
      if (this.review_entry_id == "") {
        this.$message.error("Please Add entry first~");
        return;
      }
      addActivityToReviewEntry(this.review_entry_id, row._id.$id).then(
        (res) => {
          let result = res.data;
          if (result.code == 0) {
            this.$message.success("Submit Successful");
            this.showAddActivityDialog = false;
            this.requestEntryActivities();
          } else {
            this.$message.success(result.msg);
          }
        }
      );
      // this.$alert("Are you sure add this activity to current entry?", "Tips", {
      //   confirmButtonText: "Confirm",
      //   callback: (action) => {
      //     if (action == "confirm") {

      //     }
      //   },
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-btn {
  border-radius: 5px;
  padding: 6px 10px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  background: #ff6450;
  width: fit-content;
}

.activity-container {
  cursor: pointer;
  border-left: 3px solid var(--c-primary);

  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }

  .activity-info {
    .activity-name {
      color: #333333;
    }

    .activity-time {
      color: var(--c-primary);

      span {
        color: #999999;
      }

      p {
        margin-right: 45px;
      }
    }

    .activity-location {
      color: #ff8900;

      span {
        color: #999999;
      }

      p {
        margin-right: 60px;
      }
    }

    .activity-desc {
      color: #666666;
    }
  }
}
</style>
